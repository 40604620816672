<template>
  <h1 align="center" style="font-family: sans-serif;font-weight: bold;">Vă rog, așteptați...</h1>
</template>

<script>
import { CONFIRM_ROLE } from '@/api.js'

export default {
  created() {
    const token = this.$route.params.pathMatch

    const error = msg => {
      this.$toastr.e(`Activarea rolului a eșuat. ${msg ? `(${msg})` : ''}`)
      this.goToHomePage()
    }

    CONFIRM_ROLE(token).then(res => {
      const resCode = res?.meta?.HttpStatusCode
      const resMsg = res?.data?.result

      if(this.checkHttpStatusCode(resCode)) {
        this.$toastr.s('Rolul a fost activat.')
        this.goToHomePage()
      } else if(resCode == 403) {
        if(resMsg == 'User itself still NOT ACTIVATED! Please activate user first.') {
          this.$toastr.w('Rolul nu poate fi confirmat atâta timp cât contul nu este activat.')
          this.goToHomePage()
        } else if(String(resMsg).startsWith('Role id not found')) {
          this.$toastr.w('Rolul nu a fost găsit.')
          this.goToHomePage()
        } else {
          error()
        }
      } else {
        error()
      }
    }).catch(error)
  }
}
</script>